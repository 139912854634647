<template>
  <b-row>
    <b-col cols="12">
      <RegisterForm />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { mapActions } from "vuex";
import RegisterForm from "./RegisterForm.vue";

export default {
  components: {
    BRow,
    BCol,
    RegisterForm,
  },
  created() {
    this.getProductCategoriesForVendor({
      take: 100,
      skip: 0,
    });
    this.getRegions();
    this.getBranches();
  },
  methods: {
    ...mapActions("products", ["getRegions", "getProductCategoriesForVendor", "getBranches"]),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
